import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/zeit/239a7179/src/components/layout.js";
import { graphql } from 'gatsby';
import { CodesandboxEmbed, InstagramEmbed, TweetEmbed, TypeformEmbed, VideoEmbed } from '../components/embeds';
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`;
const layoutProps = {
  pageQuery
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>



      <MDXTag name="h1" components={components} props={{
        "id": "will-it-blend"
      }}><MDXTag name="a" components={components} parentName="h1" props={{
          "href": "#will-it-blend",
          "aria-label": "will it blend permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Will it blend?`}</MDXTag>
      <MDXTag name="h3" components={components} props={{
        "id": "big-image"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#big-image",
          "aria-label": "big image permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Big image`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's see how Gatsby handles a `}<MDXTag name="em" components={components} parentName="p">{`really`}</MDXTag>{` big image:`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{
          "src": "../images/The_Earth_seen_from_Apollo_17.jpg",
          "alt": "This is the Blue Marble"
        }}></MDXTag></MDXTag>
      <MDXTag name="h3" components={components} props={{
        "id": "gatsby-graphql"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#gatsby-graphql",
          "aria-label": "gatsby graphql permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Gatsby GraphQL`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here is some data from a Gatsby GraphQL query:`}</MDXTag>
      <blockquote>
  <code>"{props.data.site.siteMetadata.description}"</code>
      </blockquote>

      <MDXTag name="h3" components={components} props={{
        "id": "syntax-highlighting"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#syntax-highlighting",
          "aria-label": "syntax highlighting permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Syntax highlighting`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is roughly the snippet that generated the GraphQL output above:`}</MDXTag>
      <MDXTag name="div" components={components} props={{
        "className": "gatsby-highlight",
        "data-language": "jsx"
      }}><MDXTag name="pre" components={components} parentName="div" props={{
          "className": "language-jsx"
        }}><MDXTag name="code" components={components} parentName="pre" props={{
            "className": "language-jsx"
          }}><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token function"
            }}>{`render`}</MDXTag>{` `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token punctuation"
            }}>{`(`}</MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token punctuation"
            }}>{`)`}</MDXTag>{` `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token punctuation"
            }}>{`{`}</MDXTag>{`
  `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token keyword"
            }}>{`return`}</MDXTag>{` `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token punctuation"
            }}>{`(`}</MDXTag>{`
    `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token tag"
            }}><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token tag"
              }}><MDXTag name="span" components={components} parentName="span" props={{
                  "className": "token punctuation"
                }}>{`<`}</MDXTag>{`blockquote`}</MDXTag><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token punctuation"
              }}>{`>`}</MDXTag></MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token plain-text"
            }}>{`
      `}</MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token tag"
            }}><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token tag"
              }}><MDXTag name="span" components={components} parentName="span" props={{
                  "className": "token punctuation"
                }}>{`<`}</MDXTag>{`code`}</MDXTag><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token punctuation"
              }}>{`>`}</MDXTag></MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token plain-text"
            }}>{`"{props.data.site.siteMetadata.description}"`}</MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token tag"
            }}><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token tag"
              }}><MDXTag name="span" components={components} parentName="span" props={{
                  "className": "token punctuation"
                }}>{`</`}</MDXTag>{`code`}</MDXTag><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token punctuation"
              }}>{`>`}</MDXTag></MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token plain-text"
            }}>{`
    `}</MDXTag><MDXTag name="span" components={components} parentName="code" props={{
              "className": "token tag"
            }}><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token tag"
              }}><MDXTag name="span" components={components} parentName="span" props={{
                  "className": "token punctuation"
                }}>{`</`}</MDXTag>{`blockquote`}</MDXTag><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token punctuation"
              }}>{`>`}</MDXTag></MDXTag>{`
  `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token punctuation"
            }}>{`)`}</MDXTag>{`
`}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token punctuation"
            }}>{`}`}</MDXTag>{`

`}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token keyword"
            }}>{`export`}</MDXTag>{` `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token keyword"
            }}>{`const`}</MDXTag>{` pageQuery `}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token operator"
            }}>{`=`}</MDXTag>{` graphql`}<MDXTag name="span" components={components} parentName="code" props={{
              "className": "token template-string"
            }}><MDXTag name="span" components={components} parentName="span" props={{
                "className": "token string"
              }}>{`\`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }

\``}</MDXTag></MDXTag></MDXTag></MDXTag></MDXTag>
      <MDXTag name="h3" components={components} props={{
        "id": "local-file-to-code-repl"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#local-file-to-code-repl",
          "aria-label": "local file to code repl permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Local file to code repl`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://codesandbox.io/api/v1/sandboxes/define?parameters=N4IgZglgNgpgziAXKADgQwMYGs0HMYB0AVnAPYB2SoGFALjObVSACYwoNvkYTzMsBmJCChp6cJgF8ANCAC2aCJUQgAnqQCuAJwC0kLRJ1oUKAPRhMxBJJkglbAB4EAFrTlRmNRgyYqAPCwQAG4ABBAsALwAOiBapKS0MQB8fqaBQUkgturaehAGtEYm5pYknnQ-wl4SIQBimCERIQAUwCEYztAsWgwhkgCUjUktUeQhIQHBSaPj437OAIxJAPLkMKmL02OzwB1dPeStIeQacgBGMFqIIQt9_ZIzE2lTo_2jo9W0IQAKPbS0qgAcqcLlpGi1BhFhs1Hn56hgtrMQsAjidzpc7kMRtskX44OgxhJVLAIsA2jQoKQriEAOQAYgAogBWABMACEmQAGGnSEJgOgAZQgAC8YNcaSyYHIaX1JElgGjQZJUvi0OREbN7rDTPCtm9yKMAEowTC0AAiywAsgQDmwtM0_L8YP8gSCMaYkryWKQMKcfAR8LQGbA5D42aoAJIsZo0uIJGn9fVZGxAA",
          "target": "_blank",
          "rel": ["noreferrer"]
        }}>{`Check this out on Codesandbox`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`These are the remark plugins that were added to mdx (linked to docs):`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.gatsbyjs.org/packages/gatsby-remark-prismjs"
          }}>{`gatsby-remark-prismjs`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.gatsbyjs.org/packages/gatsby-remark-copy-linked-files"
          }}>{`gatsby-remark-copy-linked-files`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.gatsbyjs.org/packages/gatsby-remark-images"
          }}>{`gatsby-remark-images`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.gatsbyjs.org/packages/gatsby-remark-code-repls"
          }}>{`gatsby-remark-code-repls`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.gatsbyjs.org/packages/gatsby-remark-autolink-headers"
          }}>{`gatsby-remark-autolink-headers`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components} props={{
        "id": "and-some-embeds"
      }}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "#and-some-embeds",
          "aria-label": "and some embeds permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`And some embeds:`}</MDXTag>
      <MDXTag name="h3" components={components} props={{
        "id": "codesandbox"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#codesandbox",
          "aria-label": "codesandbox permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Codesandbox`}</MDXTag>
      <CodesandboxEmbed id="3vmrlm0175" />
      <MDXTag name="h3" components={components} props={{
        "id": "instagram"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#instagram",
          "aria-label": "instagram permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Instagram`}</MDXTag>
      <InstagramEmbed url="https://www.instagram.com/p/Br5gJ4-BNro/" />
      <MDXTag name="h3" components={components} props={{
        "id": "typeform"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#typeform",
          "aria-label": "typeform permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Typeform`}</MDXTag>
      <TypeformEmbed url="https://swizecteller.typeform.com/to/DElfd8" style={{
        position: 'static',
        height: '350px'
      }} />
      <MDXTag name="h3" components={components} props={{
        "id": "twitter"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#twitter",
          "aria-label": "twitter permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`Twitter`}</MDXTag>
      <TweetEmbed id="1107783039038296064" />
      <MDXTag name="p" components={components}>{`Does this work?`}</MDXTag>
      <MDXTag name="h3" components={components} props={{
        "id": "youtube"
      }}><MDXTag name="a" components={components} parentName="h3" props={{
          "href": "#youtube",
          "aria-label": "youtube permalink",
          "className": "anchor"
        }}><MDXTag name="svg" components={components} parentName="a" props={{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><MDXTag name="path" components={components} parentName="svg" props={{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></MDXTag></MDXTag></MDXTag>{`YouTube`}</MDXTag>
      <MDXTag name="p" components={components}>{`(Also works for a ton of other video providers via `}<MDXTag name="code" components={components} parentName="p" props={{
          "className": "language-text"
        }}>{`react-player`}</MDXTag>{`.)`}</MDXTag>
      <VideoEmbed url="https://www.youtube.com/watch?v=9a4YcixDhFM" />
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      